
import { defineComponent, ref } from "vue"
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import type { RouteLocationNormalized } from 'vue-router'
import { useI18n } from "vue-i18n"
import RouteTabs from '@/views/Payment/components/RouteTabs.vue'

export default defineComponent({
  name: 'ControllingPaymentManagement',
  components: {
    RouteTabs
  },
  setup() {
    const { t } = useI18n()
    // const defaultBreadCrumbData = [
    //   {name: t('payment.payment'), path: ''},
    //   {name: 'Controlling Payment Management', path: ''}
    // ]
    const defaultBreadCrumbData: any = []
    const breadCrumbData = ref(defaultBreadCrumbData)

    const route = useRoute()
    
    const setBreadCrumbData = (route: RouteLocationNormalized) => {
      if (route.name === 'ControllingDealerRoundSummary') {
        // breadCrumbData.value = [
        //   {name: t('payment.payment'), path: ''},
        //   {name: 'Controlling Payment Management', path: '/paymentControlling'},
        //   {name: 'Dealer Payout Overview', path: ''}
        // ]
         breadCrumbData.value = [
            {name: 'Dealer Payout Overview', path: ''}
          ]
      } else {
        breadCrumbData.value = defaultBreadCrumbData
      }
    }

    setBreadCrumbData(route)
    onBeforeRouteUpdate(setBreadCrumbData)

    return {
      breadCrumbData
    }
  }
})
