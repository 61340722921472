import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ad1e169"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container d-f flex-d-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_route_tabs = _resolveComponent("route-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb, { dataSource: _ctx.breadCrumbData }, null, 8, ["dataSource"]),
    _createVNode(_component_route_tabs, { "route-name": "Controlling Payment Management" })
  ]))
}